import React from "react";
import SEO from "../../../../../components/seo";
import SharePriceDataWidget from "../../../../../components/SharePriceDataWidget";

function PriceHorizontalLightWidget() {
  return (
    <>
      <SEO />
      <SharePriceDataWidget isHorizontal isLight />
    </>
  );
}

export default PriceHorizontalLightWidget;
